var site = site || {};

site.quickshop = function(quickshopData) {

  var that = {
    productData : quickshopData
  };
  that.productData['first_sku'] = that.productData['skus'][0];
  that.productData['XL_IMAGE_0'] = that.productData['skus'][0]['XL_IMAGE'][0];
  that.productId = that.productData.PRODUCT_ID;
  that.catId = that.productData.PARENT_CAT_ID;
  that.tagIds = that.catId +  '~' + that.productId;

  //image array
  var $imageArray = that.productData['skus'][0]['XL_IMAGE'];
  if (that.productData.shaded == 1) {
      var smooshCheck = that.productData['skus'][0]['XL_IMAGE'][0].indexOf('smoosh');
      if (smooshCheck > 1) {
          $imageArray = $imageArray.slice(1, 2);
      } {
          $imageArray = $imageArray.slice(0, 1);
      }
      $smoosh = that.productData['skus'][0]['XL_SMOOSH'];
      $imageArray.push($smoosh);
  }
  that.productData['IMAGE_ARRAY'] = $imageArray;

  //multiple sizes
  var multipleSizes = _.pluck(that.productData.skus, 'PRODUCT_SIZE');
  multipleSizes = _.uniq(multipleSizes);
  multipleSizes = _.compact(multipleSizes).length > 1 ? true : false;
  if(that.productData.sized && multipleSizes){
    that.productData['multiple_sizes'] = true;
  }else if( (that.productData.sized && !multipleSizes) || that.productData.shaded){
    that.productData['multiple_sizes'] = false;
  }

  //misc flag - product level
  var misc_flag = that.productData['MISC_FLAG'];
  if(misc_flag == 1){
    that.productData['misc_flag_1'] = true;
  }else if(misc_flag == 2){
    that.productData['misc_flag_2'] = true;
  }else if(misc_flag == 3){
    that.productData['misc_flag_3'] = true;
  }else if(misc_flag == 94 || misc_flag == 5){
    that.productData['misc_flag_4'] = true;
  }

  var skus = that.productData.skus;
  $(skus).each(function(index, sku) {
    var shadeHex = sku.HEX_VALUE_STRING;
    // explode
    var shadeHex = shadeHex.split(',');
    if(shadeHex.length == 1) {
      sku['SWATCH_TYPE'] = 'single';
      sku['HEX_VALUE_1'] = shadeHex[0];
    }else if(shadeHex.length == 2){
      sku['SWATCH_TYPE'] = 'duo';
      sku['HEX_VALUE_1'] = shadeHex[0];
      sku['HEX_VALUE_2'] = shadeHex[1];
    }else if(shadeHex.length == 3){
      sku['SWATCH_TYPE'] = 'trio';
      sku['HEX_VALUE_1'] = shadeHex[0];
      sku['HEX_VALUE_2'] = shadeHex[1];
      sku['HEX_VALUE_3'] = shadeHex[2];
    }else if(shadeHex.length == 5){
      sku['SWATCH_TYPE'] = 'quint';
      sku['HEX_VALUE_1'] = shadeHex[0];
      sku['HEX_VALUE_2'] = shadeHex[1];
      sku['HEX_VALUE_3'] = shadeHex[2];
      sku['HEX_VALUE_4'] = shadeHex[3];
      sku['HEX_VALUE_5'] = shadeHex[4];
    }
    // misc flag
    var sku_misc_flag = sku.MISC_FLAG;
    if(sku_misc_flag == 1){
      sku['misc_flag_sku_1'] = true;
    }else if(sku_misc_flag == 2){
      sku['misc_flag_sku_2'] = true;
    }else if(sku_misc_flag == 3){
      sku['misc_flag_sku_3'] = true;
    }else if(sku_misc_flag == 94 || sku_misc_flag == 5){
      sku['misc_flag_sku_4'] = true;
    }
  });

  var  average = that.productData['AVERAGE_RATING'];
  var scale = 5;
  var calc = average / scale;
  var percentage = Math.round(parseFloat(calc) * 100);
  that.productData['stars_percent'] = percentage;

  var content = $('script.inline-template[path="quickshop"]').html();
  var qs_item = Mustache.render(content, that.productData);

  $.colorbox({
    html:qs_item,
    className: 'colorbox__quickshop',
    fixed: 'true',
    maxWidth: '90%',
    maxHeight: '90%',
    onComplete: function(){
      var $quickshop = $(".quickshop");
      if(that.productData.shaded){
        var qsPicker = new site.ShadePicker(that.productData);
      }
      var addBtn = site.createAddButton($(".js-add-to-cart", $quickshop));
      addBtn.updateInvStatus();
      site.addFavoritesButton($(".js-add-to-favorites", $quickshop));

      $('.shade-list .swatch', $quickshop).eq(0).trigger('click');

      var $quantitySelect = $('.quickshop__quantity');
      var $sizeSelect = $('.quickshop__price-size-select');
      //$quantitySelect.selectBox();
      //$sizeSelect.selectBox();
      if (typeof site.product !== 'undefined' && typeof site.product.view !== 'undefined' && typeof site.product.view.qsFixedBox !== 'undefined') {
        site.product.view.qsFixedBox('.quickshop__quantity');
        site.product.view.qsFixedBox('.quickshop__price-size-select');
        site.product.view.qsFixedBox('.quickshop__shade-select');
      }

      //add after XL_SMOOSH is added to tout-mpp-product
      $productImages = $('.quickshop__image-container');
      $productImage = $('img',$productImages);
      $productImageCount = $productImage.length;

      $productImage.each(function(index, val) {
        var smooshCheck = $(this).attr('src').indexOf('smoosh');
        if(smooshCheck > 1) {
           $(this).parent().addClass('smoosh');
        }
      });

      if ($productImageCount > 1) {
          var slickToggleTimer1;
          var slickToggleTimer2;
          function slickToggle() {
              slickToggleTimer1 = setTimeout(function() {
                  $('.quickshop__image-container').slick('slickNext');
                  slickToggleTimer2 = setTimeout(function() {
                      $('.quickshop__image-container').slick('slickPrev');
                  }, 3000);
              }, 3000);
          }
          $('.quickshop__image-container').slick({
              dots: true,
              arrows: false,
              infinite: false,
              speed: 300,
              slidesToShow: 1,
              slidesToScroll: 1,
              onInit: function(args) {
                  var slideLength = args.$slides.length;
                  if (slideLength >= 2) {
                      slickToggle();
                  }
              }
          });
          $('.quickshop__image-container').on('sku:select', function(e, skuData) {
              window.clearTimeout(slickToggleTimer1);
              window.clearTimeout(slickToggleTimer2);
              //update main images
              var newImageArray = [];
              newImageArray = skuData.XL_IMAGE;
              var newSmoosh = skuData.XL_SMOOSH;
              //newImageArray.splice(1, 0, newSmoosh);
              //determin order, what was last selected carousel order
              var currentSlide = $('.quickshop__image-container').slick('slickCurrentSlide');
              if ($('.quickshop__image').eq(currentSlide).hasClass('smoosh')) {
                  //add to beginning
                  newImageArray.unshift(newSmoosh);
              } else {
                  //add to end
                  newImageArray.push(newSmoosh);
              }
              //getting odd duplicate, make unique
              newImageArray = _.uniq(newImageArray);
              //current slide count
              var slideCount = $('.quickshop__image-container .slick-dots li').length;
              //create new list
              for (var i = (slideCount - 1); i >= 0; i--) {
                  $('.quickshop__image-container').slick('slickRemove', i);
              }
              $(newImageArray).each(function(index, val) {
                  //add smoosh class
                  var smooshClass = val.indexOf('smoosh') > 1 ? 'smoosh' : '';
                  //add slide
                  var newSlide = '<div class="quickshop__image ' + smooshClass + '"><img src="' + val + '" /></div>'
                  $('.quickshop__image-container').slick('slickAdd', newSlide);
              });
          });
      }

      $sizeSelect.change(function(event) {
        var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
        var skuData = _.find(that.productData.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
        site.skuSelect(skuData);
      });


      $quantitySelect.change(function(event) {
        var quantity = $(this).val();
        site.qtySelect(that.productId, quantity);
      });

      var $invStatusList = $(".js-inv-status-list", $quickshop);
      var invStatusList = site.productView.InvStatusList($invStatusList);
      invStatusList.updateInvStatus();

      //tabs
      var $qsTabControl = $('.quickshop__tab-control');
      var $qsTabs = $('.quickshop__tab');
      $qsTabs.eq(0).show();
      if($qsTabControl.length == 1){
        //hide menu
        $('.quickshop__tabs-control').hide();
      }
      $qsTabControl.addClass('quickshop__tab-control--' + $qsTabControl.length );

      $qsTabControl.click(function(event) {
        $qsTabControl.removeClass('text--bold');
        $(this).addClass('text--bold');
        var tab = $(this).attr('data-tab');
        $qsTabs.hide();
        $qsTabs.filter("[data-tab=" + tab +  "]").show();
      });

      $('.quickshop__add-button', $quickshop).click(function(event) {
        $.colorbox.close();
      });

      $('.quickshop__review-write').click(function(event) {
        event.preventDefault();
        $BV.ui("rr", "submit_review", {productId: that.productData.PROD_BASE_ID});
      });

      $( window ).resize(function() {
        $.colorbox.resize({
        width: '70%',
        height: '98%',
        });
      });

      $(document).trigger("MPP:productQV", [that.tagIds]);

      var $qs_container = $('.quickshop[data-product-id="' + that.productId + '"]');
      if($qs_container.length) {
        $(document).trigger('MPP:productQV:rendered', [$qs_container]);
      }
    },
    onClosed: function(){
      //lost reference to .selectBox() so just manually remove dropdowns
      $('.quickshop__quantity-selectBox-dropdown-menu').remove();
      $('.quickshop__price-size-select-selectBox-dropdown-menu').remove();
      $('.quickshop__shade-select-selectBox-dropdown-menu').remove();
    }
  });
  return that;
};
$(window).load(function(){
    $(".anr-night-experience__quick-shop").text("Shop now");
    if($('#anr-night-experience__anr-quick-shop-module9-4').length != 0) {
        $('#anr-night-experience__anr-quick-shop-module9-4').contents().first()[0].textContent='Shop now';
    }
    $("#anr-night-experience__anr-quick-shop_moist_2").attr("href","/product/688/38397");
    $("#anr-night-experience__anr-quick-shop_moist_5").attr("href","/product/688/36297");
    $("#anr-night-experience__anr-quick-shop_moist_6").attr("href","/product/681/17840");
    $("#anr-night-experience_moisturize_thumb_container .anr-night-experience__thumb:nth-child(3) img").attr("src","/media/export/cms/products/558x768/el_sku_R42901_558x768_0.jpg");
    $("#anr-night-experience_moisturize_thumb_container .anr-night-experience__thumb:nth-child(6) img").attr("src","/media/export/cms/products/558x768/el_sku_R3YK01_558x768_0.jpg");
    $("#anr-night-experience__anr-quick-shop-module7-6, #anr-night-experience__anr-quick-shop-module6").attr("href", "/product/536/35783/Applications/Skincare-Finder/Advanced-Night-Repair/Concentrated-Recovery-PowerFoil-Mask");

    var li_length = $('.anr-night-experience__mini_carousel').length;
    var li_height = 0;
    for (var i=1; i <= li_length ; i++ ){
        currentLiHeight = $('.anr-night-experience__mini_carousel:nth-child('+i+')').height();
        if (currentLiHeight > li_height){
            li_height = currentLiHeight;
        }
    }
    for (var j=1; j <= li_length ; j++ ){ 
        $('.anr-night-experience__mini_carousel:nth-child('+j+')').height(li_height);
    }
    $(".anr-night-experience__anr_quick_shop_container.darkened").css({"position":"absolute","bottom":"0","margin-left":"5%"});
});